import React, { Suspense, useState } from 'react';
import { FormSelect } from '../index';
import PropTypes from 'prop-types';
import Loader from '../../../../adapters/helpers/Loader';

const FormBirthdate = (
    {
        className,
        dateOfBirthLabel,
        whyWeAskForDateOfBirth,
        whyWeAskForDateOfBirthLegal,
        arrMonths,
        nameMonth,
        monthLabel,
        valueMonth,
        onChangeMonth,
        errorMonth,
        arrYear,
        nameYear,
        yearLabel,
        valueYear,
        onChangeYear,
        errorYear,
    }) => {
    const [islegalTextShowing, setIslegalTextShowing] = useState(false)
    return (
        <Suspense fallback={<Loader />}>
            <div className={`ob-birthdate ${className}`}>
                <p className={'ob-input__label ob-form-select__label'}>{dateOfBirthLabel}</p>
                <div className={'ob-form__wrapper'}>
                    <FormSelect
                        dataArray={arrMonths}
                        name={nameMonth}
                        ariaLabel={monthLabel}
                        value={valueMonth}
                        onChange={onChangeMonth}
                        hasInitialValue={true}
                    />
                    <FormSelect
                        dataArray={arrYear}
                        ariaLabel={yearLabel}
                        name={nameYear}
                        value={valueYear}
                        onChange={onChangeYear}
                        hasInitialValue={true}
                    />
                </div>
                {errorMonth && errorYear && errorMonth === errorYear ? (
                    <p id={'ob-input__error'} className="ob-input__error">
                        {errorYear}
                    </p>
                ) : (
                    <>
                        {errorMonth &&
                            <p id={`ob-input__error-${nameMonth}`} className="ob-input__error">
                                {errorMonth}
                            </p>
                        }
                        {errorYear &&
                            <p id={`ob-input__error-${nameYear}`} className="ob-input__error">
                                {errorYear}
                            </p>
                        }
                    </>
                )}
                <button aria-label={whyWeAskForDateOfBirth?.fields?.title} type={'button'} className={'ob-birthdate__why-dob'} onClick={() => setIslegalTextShowing(!islegalTextShowing)}>
                    {whyWeAskForDateOfBirth?.fields?.title}
                </button>
                <p className={`ob-birthdate__legal-text ${islegalTextShowing ? '--visible' : ''}`}>
                    {whyWeAskForDateOfBirthLegal}
                </p>
            </div>
        </Suspense>
    )
};

FormBirthdate.propTypes = {
    className: PropTypes.string,
    dateOfBirthLabel: PropTypes.string,
    whyWeAskForDateOfBirth: PropTypes.string,
    whyWeAskForDateOfBirthLegal: PropTypes.string,
    arrMonths: PropTypes.object,
    nameMonth: PropTypes.string,
    monthLabel: PropTypes.string,
    valueMonth: PropTypes.string,
    onChangeMonth: PropTypes.func,
    errorMonth: PropTypes.string,
    arrYear: PropTypes.object,
    nameYear: PropTypes.string,
    yearLabel: PropTypes.string,
    valueYear: PropTypes.string,
    onChangeYear: PropTypes.func,
    errorYear: PropTypes.string
}

export default FormBirthdate;
