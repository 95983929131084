import React from 'react'
import PropTypes from 'prop-types'
import {
    ButtonLink,
    ButtonWrapper,
    Loader,
} from './Button.styles'

const Button = ({
    disabled,
    onClick,
    url,
    variant,
    buttonText,
    isProcessing,
    buttonProcessingText,
}) => <ButtonWrapper href={url} {...(!disabled && { onClick: onClick })} variant={variant} disabled={disabled}>
        <ButtonLink variant={variant} disabled={disabled}>
            {!isProcessing ? buttonText : buttonProcessingText}
        </ButtonLink>
        {isProcessing && <Loader />}
    </ButtonWrapper>

const urlOrOnClickRequired = (props, propName, componentName) => {
    if (!props.onClick && !props.url) {
        return new Error(`One of props 'url' or 'onClick' was not specified in '${componentName}'.`)
    }
    if (props.onClick && typeof props.onClick !== 'function') {
        return new Error(`Invalid prop type of ${propName} supplied to '${componentName}'.`)
    }
    if (props.url && typeof props.url !== 'string') {
        return new Error(`Invalid prop type of ${propName} supplied to '${componentName}'.`)
    }
}

Button.propTypes = {
    buttonText: PropTypes.string.isRequired,
    url: urlOrOnClickRequired,
    onClick: urlOrOnClickRequired,
    variant: PropTypes.oneOf(['light', 'dark']),
    disabled: PropTypes.bool,
    isProcessing: PropTypes.bool,
    buttonProcessingText: PropTypes.string,
}

Button.defaultProps = {
    variant: 'light',
    disabled: false
}

export default Button
