import { observable, computed, set, keys, } from 'mobx'
import { fieldValidating } from './form.validators'

class FormStore {
    constructor(validation, fieldsToValidate) {
        this.validation = validation
        this.fieldsToValidate = fieldsToValidate
    }

    @observable formErrorResponse = {}
    @observable errorTexts = {}
    @observable form = {}

    fieldChange(key, value) {
        set(this.form, key, value)
    }

    clear() {
        this.form = {}
    }

    @computed get isInvalidForm() {
        return !!this.formErrorResponse.form
    }

    @computed get errors() {
        let _this = this;
        return keys(this.form).map(key => ({
            key,
            value: fieldValidating(_this.validation[key], _this.form[key], _this.errorTexts, _this.form)
        })).reduce((acc, curr) => {
            acc[curr.key] = curr.value
            return acc
        }, {})
    }

    @computed get formValidated() {
        const errorsArray = Object.values(this.errors);
        const isValidForm = errorsArray.every((val) => val === undefined || typeof val !== 'string');
        return keys(this.form).length >= this.fieldsToValidate &&
            isValidForm
    }

    @computed get errorsNumber() {
        const errorsArray = Object.values(this.errors);
        const newErrorsArray = errorsArray.filter((val) => val !== undefined);
        return newErrorsArray.length
    }
}

export default FormStore
