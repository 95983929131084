const passwordMinimumLength = function (input) {
    return input.length >= 8 && input.length >= 1
}

const passwordMinimumLowercase = function (input) {
    return /[a-z]/.test(input)
}

const passwordMinimumDigits = function (input) {
    return /[0-9]/.test(input)
}

const passwordMinimumUppercase = function (input) {
    return /[A-Z]/.test(input)
}

const invalidPassword = function (input) {
    return required(input) && passwordMinimumLength(input) && passwordMinimumLowercase(input) && passwordMinimumUppercase(input) && passwordMinimumDigits(input);
}

const passwordEqual = function (input, form) {
    return input === form.newPassword
}

const validEmail = function (input) {
    return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(input)
}

const emailEqual = function (input, form) {
    return input === form.emailAddress
}

const validPhone = function (input) {
    return /^$|^[/+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s/.]?[0-9]{4,6}$/.test(input)
}

const validZipCode = function (input) {
    return /^$|^\+?([0-9]{6})$/.test(input)
}

const validName = function (input) {
    return /^(\D*)$/.test(input)
}

const required = function (input) {
    return input.length !== 0
}

const mustBeNumber = function (input) {
    return /^[0-9]{1,4}$/.test(input)
}

const yearOldEnough = function (input) {
    const currentDate = new Date();
    return parseInt(input, 10) + 14 <= currentDate.getFullYear();
}

export const loginValidations = {
    signInEmailAddress: [
        {
            error: 'emailIsRequired',
            fn: required
        },
        {
            error: 'emailNotValid',
            fn: validEmail
        }
    ],
    currentPassword: [
        {
            error: 'passwordNotBlank',
            fn: required
        },
        {
            error: 'passwordIsTooShort',
            fn: passwordMinimumLength
        }
    ]
}

export const registerValidations = {
    firstName: [
        {
            error: 'firstNameIsRequired',
            fn: required
        },
        {
            error: 'noDigitsAllowed',
            fn: validName
        }
    ],
    lastName: [
        {
            error: 'lastNameIsRequired',
            fn: required
        },
        {
            error: 'noDigitsAllowed',
            fn: validName
        }
    ],
    emailAddress: [
        // validEmail is populate in register form
        {
            error: 'emailIsRequired',
            fn: required
        },
        {
            error: 'emailNotValid',
            fn: validEmail
        }
    ],
    emailAddressConfirm: [
        // validEmail is populate in register form
        {
            error: 'emailIsRequired',
            fn: required
        },
        {
            error: 'emailNotValid',
            fn: validEmail
        },
        {
            error: 'emailMustBeEqual',
            fn: emailEqual
        }
    ],
    newPassword: [
        {
            error: 'invalidPassword',
            fn: invalidPassword
        }
    ],
    newPasswordConfirm: [
        {
            error: 'passwordMustBeEqual',
            fn: passwordEqual
        }
    ],
    'birthdate[dateselect_day]': [],
    'birthdate[dateselect_month]': [
        {
            error: 'dobIsRequired',
            fn: required
        },
        {
            error: 'dobIsRequired',
            fn: mustBeNumber
        }
    ],
    'birthdate[dateselect_year]': [
        {
            error: 'dobIsRequired',
            fn: required
        },
        {
            error: 'dobIsRequired',
            fn: mustBeNumber
        }
    ],
    addressPostalCode: [
        {
            error: 'postalCodeIsRequired',
            fn: required
        }
    ],
    addressCountry: [],
    globalOpt_optStatus: [],
}

export const registerProductValidation = {
    traitProductType: [{
        traitDate: '',
        traitValue: '',
        imageUrl: '',
        registrationDate: ''
    }],
    traitBenefitPreferences: []
}

const passwordValidation = {
    signInEmailAddress: [{
        error: 'emailNotValid',
        fn: validEmail
    }]
}

export const forgotPasswordValidations = passwordValidation

export const changePasswordValidations = {
    currentPassword: [
        {
            error: 'passwordNotBlank',
            fn: required
        }
    ],
    newPassword: [
        {
            error: 'passwordNotBlank',
            fn: required
        },
        {
            error: 'passwordIsTooShort',
            fn: passwordMinimumLength
        },
        {
            error: 'passwordMustContainLowercaseLetters',
            fn: passwordMinimumLowercase
        },
        {
            error: 'passwordMustContainDigits',
            fn: passwordMinimumDigits
        }
    ],
    newPasswordConfirm: [
        {
            error: 'passwordNotBlank',
            fn: required
        },
        {
            error: 'passwordIsTooShort',
            fn: passwordMinimumLength
        },
        {
            error: 'passwordMustContainLowercaseLetters',
            fn: passwordMinimumLowercase
        },
        {
            error: 'passwordMustContainDigits',
            fn: passwordMinimumDigits
        },
        {
            error: 'passwordMustBeEqual',
            fn: passwordEqual
        }
    ]
}

export const resetPasswordValidations = changePasswordValidations

export const profileValidation = {
    emailAddress: [
        {
            error: 'emailNotValid',
            fn: validEmail
        }
    ],
    firstName: [
        {
            error: 'firstNameIsRequired',
            fn: required
        },
        {
            error: 'noDigitsAllowed',
            fn: validName
        }
    ],
    lastName: [
        {
            error: 'lastNameIsRequired',
            fn: required
        },
        {
            error: 'noDigitsAllowed',
            fn: validName
        }
    ],
    addressPostalCode: [
        // validZipCode is populated in edit profile form
        {
            error: 'postalCodeIsRequired',
            fn: required
        },
    ],
    addressCountry: [],
    'birthdate[dateselect_day]': [],
    'birthdate[dateselect_month]': [
        {
            error: 'dobIsRequired',
            fn: required
        },
        {
            error: 'dobIsRequired',
            fn: mustBeNumber
        }
    ],
    'birthdate[dateselect_year]': [
        {
            error: 'dobIsRequired',
            fn: required
        },
        {
            error: 'dobIsRequired',
            fn: mustBeNumber
        }
    ]
}

export const fieldValidating = (rules, input, errorTexts, form) => {
    for (let i = 0; i < rules.length; i++) {
        if (!rules[i].fn(input, form)) {
            return errorTexts[rules[i].error]
        }
    }
}
