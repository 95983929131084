import React from 'react'
import PropTypes from 'prop-types'

const SubmitButton = (props) => (
    <button className='ob-form__button__submit' type='submit' disabled={props.disabled} onClick={props.onClick} >
        <span>{props.buttonText}</span>
        {props.isSubmitting && <span className="ob-form__button__spinner"></span>}
        <span className='visuallyhidden' aria-live='assertive'>
            {props.isSubmitting &&
                <>{props.ariaFormIsSubmitting}</>
            }
        </span>
    </button>
);

SubmitButton.propTypes = {
    buttonText: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    isSubmitting: PropTypes.bool,
    ariaFormIsSubmitting: PropTypes.string,
    onClick: PropTypes.func
};

SubmitButton.defaultProps = {
    isSubmitting: false,
    disabled: false
};

export default SubmitButton
