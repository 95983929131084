import React, { Suspense, useEffect, useState } from 'react';
import { CheckboxInput } from '../index';
import { inputs } from '../../Janrain/janrainForms/RegisterProductForm/inputs';
import PropTypes from 'prop-types';
import Loader from '../../../../adapters/helpers/Loader';

const FormGoalsCheckboxes = ({ oralCareGoals, janrainLabels, setGoalsData, setReminderData, currentGoals, className }) => {
    const [selectedGoals, setSelectedGoals] = useState([]);
    const handleGoalSelection = event => {
        const checkbox = event.currentTarget;
        const goal = checkbox.getAttribute('value');
        const isChecked = checkbox.checked;
        let goals = [...selectedGoals];
        if (isChecked && !selectedGoals.includes(goal)) {
            goals.push(goal)
        } else {
            goals = goals.filter(elem => (elem !== goal))
        }
        setGoalsData(goals);
        setSelectedGoals(goals);
    };

    useEffect(() => {
        if (currentGoals?.length > 0) {
            setSelectedGoals([...currentGoals]);
        }
    }, [currentGoals]);

    const handleReminder = checked => {
        setReminderData(checked);
    };

    return (
        <Suspense fallback={<Loader />}>
            <div className={className}>
                <ul className='ob-form-goals__checkbox-list'>
                    {oralCareGoals.map((goal, index) => (
                        <li key={index} className='ob-form-goals__checkbox-list--item'>
                            <CheckboxInput
                                name={goal.fields.name}
                                label={goal.fields.name}
                                value={goal.fields.value}
                                onChange={handleGoalSelection}
                                checked={selectedGoals.includes(goal.fields.value)}
                                isSquared={true}
                                className={className}
                                isBold={true} />
                        </li>
                    ))}
                </ul>
                {janrainLabels.reminderToReplaceLabel &&
                    <CheckboxInput
                        name={inputs.field.reminderToReplace}
                        label={janrainLabels.reminderToReplaceLabel}
                        className={className}
                        checked={false}
                        onChange={(e) => { handleReminder(e.target.checked) }} />
                }
            </div>
        </Suspense>
    )
};

FormGoalsCheckboxes.propTypes = {
    oralCareGoals: PropTypes.array,
    janrainLabels: PropTypes.object,
    setGoalsData: PropTypes.func,
    setReminderData: PropTypes.func,
    currentGoals: PropTypes.object,
    className: PropTypes.string
}

FormGoalsCheckboxes.defaultProps = {
    className: ''
}

export default FormGoalsCheckboxes;
