import React, { Suspense, useEffect, useState } from 'react';
import { inputs } from '../../Janrain/janrainForms/ProfileForm/inputs';
import { FormBirthdate, FormSelect, SubmitButton } from '../index';
import Modal from 'react-modal';
import { setMonths, setYears } from '../../../../adapters/helpers/Utils';
import useJanrainGlobalState from '../../Janrain/janrainForms/useJanrainGlobalState';
import PropTypes from 'prop-types';
import { CountryCode, Constants } from '../../../../adapters/helpers/Constants';
import { CheckboxInput } from '../../components';
import { getJanrainCaptureProfileDataFromLocalStorage } from '../../helpers/localstorage.helpers';
import { getPostReplaceEntityObject, postReplaceBenefitPreferences } from '../../../../adapters/model/service/api/svc';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import Loader from '../../../../adapters/helpers/Loader';
const Button = dynamic(() => import('../../../Button/Button'));
const FormInput = dynamic(() => import('../FormInput/FormInput'));

const EditProfile = ({ profileStore, errors, form, userData, profileEditTab, commonLabels, janrainErrors, formValidated, customStyles, closeModalTimeoutMS }) => {
    const [isFormProcessing, setFormProcessing] = useState(false);
    const [arrMonths, setArrMonths] = useState([]);
    const [arrYear, setArrYear] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
    const [areChangesSaved, setAreChangesSaved] = useState(false);
    const [arrCountries, setArrCountries] = useState([]);
    const [globalState, setGlobalState] = useJanrainGlobalState();
    const optStatus = userData?.optIns && userData?.optIns[Constants.optinIdx]?.optStatus;
    const [emailOptStatus, setEmailOptStatus] = useState(optStatus);
    const postalCodeValidationRegex = profileEditTab?.postalCodeValidationRegex?.fields?.pattern;
    const postalCodeSecondPattern = profileEditTab?.postalCodeValidationRegex?.fields?.secondPattern;
    const minimumAge = profileEditTab?.minimumAge || 18;

    const janrainLabels = {
        title: profileEditTab?.title,
        modalCancelButton: profileEditTab?.modalCancelButton?.fields?.text,
        allFieldsRequiredLabel: profileEditTab?.allFieldsRequiredLabel?.fields?.text,
        firstName: profileEditTab?.firstName?.fields?.text,
        lastName: profileEditTab?.lastName?.fields?.text,
        email: profileEditTab?.email?.fields?.text,
        dateOfBirth: profileEditTab?.dateOfBirth?.fields?.text,
        whyWeAskForDateOfBirth: profileEditTab?.whyWeAskForDateOfBirth,
        whyWeAskForDateOfBirthLegal: profileEditTab?.whyWeAskForDateOfBirthLegal,
        registerDateOfBirthPlaceholder: profileEditTab?.registerDateOfBirthPlaceholder?.fields?.text,
        dateValidationRegex: profileEditTab?.dateValidationRegex,
        monthSelectLabel: profileEditTab?.monthSelectLabel?.fields?.text,
        yearSelectLabel: profileEditTab?.yearSelectLabel?.fields?.text,
        postCode: profileEditTab?.postCode?.fields?.text,
        country: profileEditTab?.country?.fields?.text,
        changePasswordLink: profileEditTab?.changePasswordLink,
        emailDisclaimer: profileEditTab?.profileEmailAccountDisclaimer,
        ...commonLabels
    };

    const regExpPattern = (patternFormat) => {
        const pattern = new RegExp(patternFormat);
        return pattern;
    }

    const validPostalCode = function (input) {
        const pattern = regExpPattern(postalCodeValidationRegex);
        if (pattern.test(input)) {
            return true;
        } else {
            const secPattern = regExpPattern(postalCodeSecondPattern);
            return secPattern.test(input);
        }
    };

    // Is duplicated in register form, we try not to, but we need variable from the block context/scope
    const validAge = function (input) {
        let birthMonth = profileStore.form['birthdate[dateselect_month]'] - 1 || 0;
        const currentDate = new Date();
        const birthDate = new Date(input, birthMonth);
        // 31536000000 is the number of milisec in a year
        const age = (currentDate - birthDate) / 31536000000;
        return minimumAge <= age;
    };

    useEffect(() => {
        if (formValidated) {
            setIsSubmitDisabled(false);
        } else {
            setIsSubmitDisabled(true);
        }
    });

    useEffect(() => {
        profileStore.clear();
        profileStore.errorTexts = janrainErrors;
        profileStore.rehydrateForProfileForm(userData);
        setArrMonths(setMonths(janrainLabels.monthSelectLabel));
        setArrYear(setYears(janrainLabels.yearSelectLabel));
        if (document.querySelector('.js-modal-container')) {
            Modal.setAppElement('.js-modal-container');
        }
        profileStore.validation.addressPostalCode.push({
            error: 'postalCodeIsRequired',
            fn: validPostalCode
        });
        // dynamic minimumAge validation validation
        profileStore.validation['birthdate[dateselect_year]'].push({
            error: 'yearMustBeOldEnough',
            fn: validAge
        });
        const countries = profileEditTab?.countries;
        if (countries) {
            let newCountriesArr = [];
            for (let i = 0; i < countries.length; i++) {
                const country = countries[i].fields.text;
                const countryCode = CountryCode[country] || country;
                newCountriesArr.push({ value: country, id: countryCode });
            }
            setArrCountries(newCountriesArr);
        }
    }, []);

    const handleEditProfileSendSuccess = () => {
        // updating the email opt status
        const janrainProfileData = JSON.parse(getJanrainCaptureProfileDataFromLocalStorage());
        const postObject = getPostReplaceEntityObject(janrainProfileData);
        if (postObject?.[Constants.optins]?.[Constants.optinIdx]) {
            postObject[Constants.optins][Constants.optinIdx].optStatus = emailOptStatus;
        }
        postReplaceBenefitPreferences(postObject, handleFormSendSuccess, handleFormSendFailed);
    };

    useEffect(() => {
        if (isFormProcessing) {
            const formName = inputs.formName;
            let reminderFieldValues = {
                firstName: form.firstName,
                lastName: form.lastName,
                emailAddress: form.emailAddress,
                'birthdate[dateselect_day]': form['birthdate[dateselect_day]'],
                'birthdate[dateselect_month]': form['birthdate[dateselect_month]'],
                'birthdate[dateselect_year]': form['birthdate[dateselect_year]'],
                addressPostalCode: form.addressPostalCode,
                addressCountry: form.addressCountry
            };
            globalState.givenName = form.firstName;
            setGlobalState(globalState);
            window.janrain.events.onCaptureSaveSuccess.addHandler(handleEditProfileSendSuccess);
            window.janrain.events.onCaptureSaveFailed.addHandler(handleFormSendFailed);
            window.janrain.capture.ui.postCaptureForm(formName, reminderFieldValues);
        }
    }, [isFormProcessing]);

    const handleFormSendFailed = () => {
        setFormProcessing(false);
        setAreChangesSaved(false);
    };

    const handleFormSendSuccess = () => {
        setFormProcessing(false);
        setAreChangesSaved(true);
    };

    const handleSubmit = () => {
        setFormProcessing(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const openModal = event => {
        event.preventDefault();
        setAreChangesSaved(false);
        if (formValidated) {
            setIsModalOpen(true);
        }
    };

    return (
        <Suspense fallback={<Loader />}>
            <div className={'ob-edit-profile'}>
                <p className={'ob-edit-profile__fields-required'}>{janrainLabels.allFieldsRequiredLabel}</p>
                <form className={'js-modal-container'}>
                    <div className="ob-form__wrapper ob-register__row">
                        <div className="ob-form__input-wrapper">
                            <FormInput
                                label={janrainLabels.firstName}
                                name={inputs.field.firstName}
                                inputType={inputs.type.text}
                                value={form.firstName || ''}
                                onChange={(e) => profileStore.fieldChange(inputs.field.firstName, e.target.value)}
                                validationErrorText={errors.firstName}
                            />
                        </div>
                        <div className="ob-form__input-wrapper">
                            <FormInput
                                label={janrainLabels.lastName}
                                name={inputs.field.lastName}
                                inputType={inputs.type.text}
                                value={form.lastName || ''}
                                onChange={(e) => profileStore.fieldChange(inputs.field.lastName, e.target.value)}
                                validationErrorText={errors.lastName}
                            />
                        </div>
                    </div>
                    <div className="ob-form__wrapper ob-register__row">
                        <div className="ob-form__input-wrapper">
                            <FormInput
                                label={janrainLabels.email}
                                name={inputs.field.emailAddress}
                                inputType={inputs.type.email}
                                value={form.emailAddress || ''}
                                disabled={true}
                                onChange={(e) => profileStore.fieldChange(inputs.field.emailAddress, e.target.value)}
                                validationErrorText={errors.emailAddress}
                            />
                        </div>
                    </div>
                    <div className="ob-form__wrapper ob-register__row ob-edit-profile__location">
                        <div className="ob-form__wrapper ob-edit-profile__half-width">
                            <FormBirthdate
                                dateOfBirthLabel={janrainLabels.dateOfBirth}
                                whyWeAskForDateOfBirth={janrainLabels.whyWeAskForDateOfBirth}
                                whyWeAskForDateOfBirthLegal={janrainLabels.whyWeAskForDateOfBirthLegal}
                                arrMonths={arrMonths}
                                nameMonth={inputs.field.birthdate.dateselect_month}
                                monthLabel={janrainLabels.monthSelectLabel}
                                valueMonth={form['birthdate[dateselect_month]'] || janrainLabels.monthSelectLabel}
                                onChangeMonth={(e) => profileStore.fieldChange(inputs.field.birthdate.dateselect_month, parseInt(e.target.value, 10))}
                                errorMonth={errors['birthdate[dateselect_month]']}
                                arrYear={arrYear}
                                nameYear={inputs.field.birthdate.dateselect_year}
                                yearLabel={janrainLabels.yearSelectLabel}
                                valueYear={form['birthdate[dateselect_year]'] || janrainLabels.yearSelectLabel}
                                onChangeYear={(e) => profileStore.fieldChange(inputs.field.birthdate.dateselect_year, e.target.value)}
                                errorYear={errors['birthdate[dateselect_year]']}
                            />
                            <div className="ob-form__input-wrapper">
                                <FormInput
                                    label={janrainLabels.postCode}
                                    name={inputs.field.addressPostalCode}
                                    inputType={inputs.type.text}
                                    value={form.addressPostalCode || ''}
                                    onChange={(e) => profileStore.fieldChange(inputs.field.addressPostalCode, e.target.value)}
                                    validationErrorText={errors.addressPostalCode}
                                />
                            </div>
                        </div>
                        <div className={'ob-edit-profile__select-country'}>
                            <FormSelect
                                selectLabel={janrainLabels.country}
                                name={inputs.field.addressCountry}
                                value={form.addressCountry || ''}
                                onChange={(e) => profileStore.fieldChange(inputs.field.addressCountry, e.target.value)}
                                dataArray={arrCountries}
                            />
                        </div>
                    </div>
                    {janrainLabels.emailDisclaimer &&
                        <>
                            <div className="ob-form__form-checkbox">
                                <CheckboxInput
                                    className={'checkbox-email-disclaimer'}
                                    label={`${janrainLabels.emailDisclaimer}`}
                                    name={'globalOpt_optStatus'}
                                    value={emailOptStatus || false}
                                    checked={emailOptStatus}
                                    onChange={() => setEmailOptStatus(!emailOptStatus)}
                                />
                            </div>
                        </>
                    }
                    <Button
                        type={'button'}
                        onClick={openModal}
                        disabled={isSubmitDisabled}
                        size={'large-slim'}
                        className={'ob-profile__full-width-btn ob-edit-profile__save-btn'}
                    >
                        {janrainLabels.saveLabel}
                    </Button>
                    <Link aria-label={janrainLabels.changePasswordLink?.fields?.title} href={janrainLabels.changePasswordLink?.fields?.url} className={'ob-edit-profile__change-pwd-link'}>
                        {janrainLabels.changePasswordLink?.fields?.title}
                    </Link>
                    <Modal
                        isOpen={isModalOpen}
                        onRequestClose={closeModal}
                        closeTimeoutMS={closeModalTimeoutMS}
                        style={customStyles}
                        htmlOpenClassName={'ob-profile__modal-overlay'}
                    >
                        <div className={'ob-profile__modal-container ob-edit-profile__modal-container'}>
                            <div>
                                <p className={'ob-profile__modal-title'}>
                                    {janrainLabels.editModalSaveChangesTitle}
                                </p>
                            </div>
                            <div>
                                {!areChangesSaved ? (
                                    <>
                                        <SubmitButton
                                            buttonText={janrainLabels.saveChangesLabel}
                                            isSubmitting={isFormProcessing}
                                            onClick={handleSubmit}
                                        />
                                        <Button textTheme={true}
                                            className={'ob-profile__modal-cancel-btn'}
                                            onClick={closeModal}>
                                            {janrainLabels.modalCancelButton}
                                        </Button>
                                    </>
                                ) : (
                                    <>
                                        <p className={'ob-edit-profile__success'}>{janrainLabels.editModalSuccess}</p>
                                        <Button textTheme={true}
                                            className={'ob-profile__modal-cancel-btn'}
                                            onClick={closeModal}>
                                            {janrainLabels.closeModal}
                                        </Button>
                                    </>
                                )}
                            </div>
                        </div>
                    </Modal>
                </form>
            </div>
        </Suspense>
    )
};

export default EditProfile;

EditProfile.propTypes = {
    profileStore: PropTypes.object,
    errors: PropTypes.object,
    form: PropTypes.object,
    userData: PropTypes.object,
    profileEditTab: PropTypes.object,
    commonLabels: PropTypes.object,
    janrainErrors: PropTypes.object,
    formValidated: PropTypes.bool,
    customStyles: PropTypes.string,
    closeModalTimeoutMS: PropTypes.number
};
