import React, { Suspense, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Loader from '../../../../adapters/helpers/Loader';

const CheckboxInput = ({
    label,
    name,
    value,
    checked,
    isBold,
    isSquared,
    onChange,
    isWhite,
    isSmall,
    error,
    errorMessage,
    isRequired,
    className,
    isRound,
}) => {
    const [isChecked, setChecked] = useState(checked);
    const onChangeEvent = (e) => {
        setChecked(!isChecked);
        if (onChange) {
            onChange(e);
        }
    };

    useEffect(() => {
        setChecked(checked);
    }, [checked]);

    return (
        <Suspense fallback={<Loader />}>
            <div className={`ob-checkbox__wrapper ${className}`}>
                <input
                    className="ob-checkbox__input"
                    required={isRequired}
                    id={name}
                    name={name}
                    value={value}
                    checked={isChecked}
                    onChange={(e) => onChangeEvent(e)}
                    type='checkbox'
                />
                <label
                    htmlFor={name}
                    data-is-bold={isBold}
                    data-is-squared={isSquared}
                    data-is-round={isRound}
                    data-is-white={isWhite}
                    data-is-small={isSmall}
                    className='ob-checkbox__label'>
                    <span className='ob-checkbox__custom-box'></span>
                    <span dangerouslySetInnerHTML={{ __html: label }}></span>
                </label>
                {(error && errorMessage) &&
                    <p className={'ob-checkbox__error'}>{errorMessage}</p>
                }
            </div>
        </Suspense>
    )
};

CheckboxInput.defaultProps = {
    checked: false,
    isBold: false,
    isSquared: false,
    isWhite: false,
    error: false,
    className: '',
    isRound: false
};

CheckboxInput.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    checked: PropTypes.bool.isRequired,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool
    ]),
    isBold: PropTypes.bool,
    isSquared: PropTypes.bool,
    isWhite: PropTypes.bool,
    isSmall: PropTypes.bool,
    isRequired: PropTypes.bool,
    error: PropTypes.bool,
    errorMessage: PropTypes.string,
    onChange: PropTypes.func,
    className: PropTypes.string,
    isRound: PropTypes.bool,
};

export default CheckboxInput
